import {useEffect, useState} from "react";
import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {TableColumn} from "../../common/table/Table";

interface Row {
  qrs: string;
}

export function BatteryAddTable(args: {
  qrs: string[];
}) {
  const {qrs} = args;

  const [rows, setRows] = useState<Row[]>([]);

  useEffect(() => {
    const _rows: Row[] = [];
    for (const i in qrs) {
      _rows.push({
        qrs: qrs[i],
      })
    }
    setRows(_rows)
  }, [qrs]);

  const columns: ColumnsType<Row> = [
    TableColumn("배터리", row => row.qrs),
  ]

  return (
    <Table columns={columns} dataSource={rows}/>
  )
}


