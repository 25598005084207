import {useState} from "react";

export function useProgress() {
  const [progress, setProgress] = useState(0)

  function increaseProgress() {
    setProgress(prev => prev + 1)
  }

  function clearProgress() {
    setProgress(0)
  }

  return {
    progress,
    increaseProgress,
    clearProgress,
  }
}
