import styled from "styled-components";
import {ChangeEvent} from "react";

const Container = styled.div`
  display: grid;
`

const Head = styled.p<{ isTop: boolean, isLeft: boolean }>`
  margin: 0;
  border-style: none;
  border-top: ${props => props.isTop && "0.3px black solid"};
  border-left: ${props => props.isLeft && "0.3px black solid"};
  border-bottom: 0.3px black solid;
  border-right: 0.3px black solid;
  padding: 4px;
  resize: none;
  height: 32px;
  text-align: center;
  color: #6e6e6e;
  font-size: 16px;
  background-color: #d5d5d5;
`

const Cell = styled.textarea<{ isTop?: boolean, isLeft?: boolean }>`
  border-style: none;
  border-top: ${props => props.isTop && "0.3px black solid"};
  border-left: ${props => props.isLeft && "0.3px black solid"};
  border-bottom: 0.3px black solid;
  border-right: 0.3px black solid;
  padding: 4px;
  resize: none;
  height: 32px;
  font-size: 14px;

  :focus {
    outline: none;
    box-shadow: 0 0 0 1px #000 inset;
  }
`

type Data = Record<string, any>

export interface SheetColumn<T> {
  title: string
  key: keyof T
}

interface Position {
  x: number
  y: number
}

const CellId = (position: Position) => {
  return `cell-${position.x}-${position.y}`
}

export function SheetInput<T extends Data>(props: {
  columns: SheetColumn<T>[],
  rows: T[],
  onChange: (rows: T[]) => void
}) {
  const {columns, rows, onChange} = props
  const columnKeys = columns.map(it => it.key)

  const gridTemplateColumns = ["40px", ...new Array(columns.length).fill("1fr")].join(" ")

  const defaultRow = (): T => {
    const row = {} as T

    // @ts-ignore
    columnKeys.forEach(key => row[key] = "")

    return row
  }

  function handleChange(position: Position) {
    return (e: ChangeEvent<HTMLTextAreaElement>) => {
      const input = e.target.value
      const nextRows = [...rows] as T[]

      input.split("\n").forEach((line, y) => {
        line.split("\t").forEach((value, x) => {
          const row = position.y + y

          if (row > nextRows.length - 1) {
            nextRows.push(defaultRow())
          }

          const targetRow = nextRows[row]
          const key = columns[position.x + x].key
          // @ts-ignore
          targetRow[key] = value
        })
      })

      onChange(nextRows)
    }
  }

  function getCell(position: Position): HTMLTextAreaElement | null {
    return document.getElementById(CellId(position)) as HTMLTextAreaElement | null
  }

  function focusCell(position: Position) {
    const cell = getCell(position)
    if (cell) cell.focus()
  }

  return (
    <Container
      style={{
        gridTemplateColumns
      }}
    >
      <Head isTop={true} isLeft={true}/>
      {columns.map((it, x) => <Head isTop={true} isLeft={false} children={it.title}/>)}

      {rows.map((row, y) => {
        const cols = columns.map((it, x) => {
          const value = row[it.key]
          const position: Position = {x, y}
          return (
            <Cell
              id={CellId(position)}
              onKeyDown={e => {
                switch (e.key) {
                  case "ArrowUp" :
                    focusCell({x: position.x, y: position.y - 1})
                    break
                  case "ArrowDown" :
                    focusCell({x: position.x, y: position.y + 1})
                    break
                  case "ArrowLeft" :
                    focusCell({x: position.x - 1, y: position.y})
                    break
                  case "ArrowRight" :
                    focusCell({x: position.x + 1, y: position.y})
                    break
                }
              }}
              onChange={handleChange(position)}
              value={value}
            />
          )
        })

        return [<Head isTop={false} isLeft={true} children={y + 1}/>, ...cols]
      })}
    </Container>
  )
}
