import {Api} from "../../common/api/Api";
import {MatchBatteryBody, MatchMopedBody, ScootAddBody, ScootDto, SpareAddBody} from "./scoot.interface";

export async function getOnlineSessions(): Promise<string[]> {
  return await Api
    .get<string[]>("/scoots/sessions")
    .then(resp => resp.data)
}

export async function findScootsByQr(args: {
  qrs: string[]
}): Promise<ScootDto[]> {
  const {qrs} = args

  return await Api.get<ScootDto[]>("/scoots", {
    params: {
      qrs,
    },
  })
    .then(resp => resp.data)
}

export async function getSpareIots(): Promise<ScootDto[]> {
  return await Api
    .get<ScootDto[]>("/scoots/spares")
    .then(resp => resp.data)
}

export async function addScoot(body: ScootAddBody) {
  return await Api.post("/scoots", body)
}

export async function addSpareIOT(body: SpareAddBody) {
  return await Api.post("/scoots/spares", body)
}

export async function matchScootBattery(body: MatchBatteryBody) {
  await Api.post("/scoot/match-battery", body)
}

export async function postMatchMoped(body: MatchMopedBody): Promise<void> {
  await Api.post("/scoot/match-moped", body)
}

export async function setScootImport(scootId: string) {
  await Api.put(`/scoots/${scootId}/import`)
}

export async function setScootExport(scootId: string) {
  await Api.put(`/scoots/${scootId}/export`)
}

export async function setScootBroken(scootId: string, broken: boolean) {
  await Api.put(`/scoots/${scootId}/broken`, {
    isBroken: broken,
  })
}

export async function setScootBand(args: {
  scootId: string
  bandId: string
}) {
  const {bandId, scootId} = args
  await Api.put(`/scoots/${scootId}/band`, {
    bandId,
  })
}