import {Button, Card, Col, Descriptions, message, Modal, Row} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import {ScootTable} from "../scoot/component/ScootTable";
import {useScootsByQr} from "../scoot/hook/useScootsByQr";
import {useQrs} from "../scoot/hook/useQrs";
import {setScootBroken, setScootExport, setScootImport} from "../scoot/service/scoot.service";

export function ScootListPage() {

  const {qrs, OpenButton, InputModal} = useQrs()

  const {scoots, render, loading} = useScootsByQr({qrs})

  function handleImport() {
    Modal.confirm({
      title: "입고 처리",
      content: `${scoots.length} 대 킥보드 입고 처리`,
      onOk: async () => {
        for (const scoot of scoots) {
          await setScootImport(scoot.scootId).catch(e => {
            message.error(`${scoot.qr} ${e.message}`)
          })
        }
        message.success("입고 처리 완료")
        render()
      },
    })
  }

  function handleExport() {
    Modal.confirm({
      title: "출고 처리",
      content: `${scoots.length} 대 킥보드 출고 처리`,
      onOk: async () => {
        for (const scoot of scoots) {
          await setScootExport(scoot.scootId).catch(e => {
            message.error(`${scoot.qr} ${e.message}`)
          })
        }
        message.success("출고 처리 완료")
        render()
      },
    })
  }

  function handleResetBroken() {
    Modal.confirm({
      title: "정상 처리",
      content: `${scoots.length} 대 킥보드 정상 처리`,
      onOk: async () => {
        for (const scoot of scoots) {
          await setScootBroken(scoot.scootId, false)
        }
        message.success("정상 처리 완료")
        render()
      },
    })
  }

  return (
    <>
      <InputModal/>

      <Card
        size={"small"}
        title={"킥보드 관리"}
      >
        <Descriptions
          bordered
          size={"small"}
          labelStyle={{width: 160}}
          column={1}
        >
          <DescriptionsItem label={"검색"}><OpenButton/></DescriptionsItem>
        </Descriptions>
      </Card>

      <Card
        size={"small"}
        title={"상태 조작"}
      >
        <Descriptions
          bordered
          size={"small"}
          labelStyle={{width: 160}}
          column={1}
        >
          <DescriptionsItem label={"입고 / 출고"}>
            <Row gutter={8}>
              <Col><Button onClick={handleImport}>입고</Button></Col>
              <Col><Button onClick={handleExport}>출고</Button></Col>
            </Row>
          </DescriptionsItem>
          <DescriptionsItem label={"고장 처리"}>
            <Row gutter={8}>
              <Col><Button onClick={handleResetBroken}>정상</Button></Col>
            </Row>
          </DescriptionsItem>
        </Descriptions>
      </Card>

      <Card
        size={"small"}
        title={"킥보드 목록"}
      >
        <ScootTable data={scoots} loading={loading}/>
      </Card>
    </>
  )
}
