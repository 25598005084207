import {useSearchInput} from "../../common/input/useSearchInput";
import {useEffect, useState} from "react";
import {SheetFilterDto} from "../service/sheet.interface";

export function useSheetFilter() {
  const {search: keyword, setSearch: setKeyword} = useSearchInput()

  const [includeInit, setIncludeInit] = useState(true)

  const [filter, setFilter] = useState<SheetFilterDto>({keyword, includeInit})

  useEffect(() => {
    setFilter({
      keyword, includeInit,
    })
  }, [keyword, includeInit])

  return {
    filter,
    setKeyword,
    setIncludeInit,
  }
}
