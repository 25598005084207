import {Button, Card, Col, Descriptions, Row, Switch, Typography} from "antd";
import {SheetTable} from "../sheet/component/SheetTable";
import {usePageRequest} from "../common/api/Pagination";
import {useSheets} from "../sheet/hook/useSheets";
import {TablePagination} from "../common/table/Table";
import DescriptionsItem from "antd/es/descriptions/Item";
import Search from "antd/es/input/Search";
import {useSheetFilter} from "../sheet/hook/useSheetFilter";
import {SheetAddModal} from "../sheet/component/SheetAddModal";
import {useModalOpen} from "../common/modal/useModalOpen";

export function SheetListPage() {

  const {setPageRequest, pageRequest} = usePageRequest()
  const {setKeyword, filter, setIncludeInit} = useSheetFilter()

  const {sheets, total, loading, render} = useSheets({
    pageRequest, filter,
  })

  const pagination = TablePagination({
    page: pageRequest.page,
    size: pageRequest.size,
    onPageChange: setPageRequest,
    total,
  })

  const {open, isOpen, close} = useModalOpen()

  function handleClose() {
    close()
    render()
  }

  return (
    <>
      <SheetAddModal isOpen={isOpen} onClose={handleClose}/>
      <Card
        size={"small"}
        title={"시트 관리"}
      >
        <Descriptions
          bordered
          size={"small"}
          column={1}
          labelStyle={{width: 200}}
        >
          <DescriptionsItem label={"등록"}>
            <Button type={"primary"} onClick={open}>등록하기</Button>
          </DescriptionsItem>
          <DescriptionsItem label={"검색"}>
            <Search style={{width: 400}} onSearch={setKeyword} loading={loading}/>
          </DescriptionsItem>
          <DescriptionsItem label={"생성된 킥보드 포함"}>
            <Row align={"middle"} gutter={8}>
              <Col><Switch checked={filter.includeInit} onChange={setIncludeInit} loading={loading}/></Col>
              <Col>
                <Typography.Text disabled={!filter.includeInit}>
                  {filter.includeInit ? "포함됨" : "미포함"}
                </Typography.Text>
              </Col>
            </Row>
          </DescriptionsItem>
        </Descriptions>
      </Card>

      <Card
        size={"small"}
        title={"시트 목록"}
      >
        <SheetTable data={sheets} pagination={pagination} loading={loading}/>
      </Card>
    </>
  )
}
