import {ScootDto} from "../service/scoot.interface";
import {ColumnsType} from "antd/es/table";
import {TableBase, ITableProps, TableColumn} from "../../common/table/Table";
import {useOnlineSessions} from "../hook/useOnlineSessions";
import {Badge} from "antd";

export function ScootTable(args: ITableProps<ScootDto>) {

  const {sessions} = useOnlineSessions()

  const columns: ColumnsType<ScootDto> = [
    TableColumn("ID", row => row.scootId),
    TableColumn("QR", row => row.qr),
    TableColumn("IMEI", row => {
      const isOnline = sessions.includes(row.imei)
      return <Badge color={isOnline ? "green" : "lightgrey"} text={row.imei}/>
    }),
    TableColumn("MAC", row => row.mac),
    TableColumn("소유자", row => row.ownerName),
    TableColumn("지역", row => row.bandName),
    TableColumn("상태", row => row.status),
    TableColumn("고장", row => row.isBroken ? "고장" : "-"),
  ]

  return (
    <TableBase props={args} columns={columns}/>
  )
}
