import * as xlsx from 'xlsx';
import {Button, Upload, UploadFile} from "antd";
import {UploadOutlined} from '@ant-design/icons';
import {UploadChangeParam} from "antd/es/upload";

interface Props {
  onUpload: (lines: string[]) => void;
}

export function UploadExcelButton(props: Props) {

  function handleExcelUpload(info: UploadChangeParam<UploadFile>) {
    if (info.fileList.length === 0) return;
    if (!info.file.originFileObj) return;

    if (info.file.status === 'uploading') {
      const reader = new FileReader();

      reader.onload = function () {
        const rawData = reader.result;
        const excelData = xlsx.read(rawData, {type: 'array'});
        const sheetName = excelData.SheetNames[0];

        const lines = xlsx.utils.sheet_to_csv(excelData.Sheets[sheetName]).split("\n")
        props.onUpload(lines)
      }

      reader.readAsArrayBuffer(info.file.originFileObj);
    }
  }

  return (
    <Upload
      accept={".xlsx"}
      onChange={handleExcelUpload}
      showUploadList={false}
    >
      <Button icon={<UploadOutlined/>}>
        Click to Upload
      </Button>
    </Upload>
  )
}