import {Button, Card, Descriptions, message, Modal} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import {SheetTable} from "../sheet/component/SheetTable";
import {useSheetsByQr} from "../sheet/hook/useSheetsByQr";
import {useQrs} from "../scoot/hook/useQrs";
import {ScootAddBody} from "../scoot/service/scoot.interface";
import {useEffect, useState} from "react";
import {useLoading} from "../common/api/useLoading";
import {addScoot} from "../scoot/service/scoot.service";
import {useModalOpen} from "../common/modal/useModalOpen";

export function ScootRegisterPage() {

  const {qrs, OpenButton, InputModal, setQrs} = useQrs()

  const {sheets} = useSheetsByQr(qrs)

  const [values, setValues] = useState<ScootAddBody[]>([])

  const [progress, setProgress] = useState(0)

  const {loading, setLoading, setDone} = useLoading()

  useEffect(() => {
    setValues(sheets
      .filter(it => !it.hasInit)
      .map<ScootAddBody>(it => ({
        modelName: it.modelName,
        vehicleType: it.vehicleType,
        mac: it.mac,
        imei: it.imei,
        qr: it.qr,
      })),
    )
  }, [sheets])

  const {isOpen, open, close} = useModalOpen()

  async function create() {
    setLoading()
    for (const value of values) {
      await addScoot(value).catch(() => {
        message.error(`${value.qr} 생성 실패`)
      })
      setProgress(prev => prev + 1)
    }
    setProgress(0)
    setQrs([])
    close()
    setDone()
  }

  return (
    <>
      <InputModal/>
      <Modal
        title={`${values.length}대 킥보드 생성`}
        closable={!loading}
        open={isOpen}
        onOk={create}
        confirmLoading={loading}
      >
        {progress > 0 && (`${progress}/${values.length}대 생성됨`)}
      </Modal>
      <Card
        title={"킥보드 등록"}
        size={"small"}
      >
        <Descriptions
          bordered
          size={"small"}
          column={1}
          labelStyle={{width: 200}}
        >
          <DescriptionsItem label={"검색"}>
            <OpenButton/>
          </DescriptionsItem>
          <DescriptionsItem label={"생성"}>
            <Button type={"primary"} onClick={open} disabled={values.length < 1}>{values.length}대 생성</Button>
          </DescriptionsItem>
        </Descriptions>
      </Card>

      <Card
        title={"등록할 시트"}
        size={"small"}
      >
        <SheetTable data={sheets}/>
      </Card>
    </>
  )
}
