import {useState} from "react";

import {Button, Card, Descriptions, message, Space} from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import {MopedTemplateButton, MopedTemplateUpload} from "../scoot/component/MopedTemplate";
import {SheetColumn, SheetInput} from "../common/input/SheetInput";
import {MatchMopedBody} from "../scoot/service/scoot.interface";
import {postMatchMoped} from "../scoot/service/scoot.service";


const defaultRows: MatchMopedBody[] = [{vin: "", lpn: "", imei: ""}]
const MAX_COUNT = 200

function isValid(row: MatchMopedBody) {
  return row.vin && row.lpn && row.imei
}

export function MatchMopedPage() {
  const [rows, setRows] = useState<MatchMopedBody[]>([...defaultRows])

  const validRows = rows.filter(isValid)
  const targetCount = Math.min(validRows.length, MAX_COUNT)
  const isReady = validRows.length > 0 && validRows.length <= MAX_COUNT
  const columns: SheetColumn<MatchMopedBody>[] = [
    {key: "imei", title: "IMEI"},
    {key: "vin", title: "VIN"},
    {key: "lpn", title: "차량번호"},
  ]

  async function handleMatch() {
    console.log(validRows)

    for (const row of validRows) {
      await postMatchMoped(row)
        .catch(e => {
          console.error(e)
          message.error(`${row.lpn} 매칭 실패`)
        })
    }

    message.success(`${validRows.length} 대 매칭완료`)

    clear()
  }

  function handleClear() {
    clear()
    message.info(`목록이 초기화되었습니다`)

  }

  function clear() {
    setRows([...defaultRows])
  }

  return (
    <>
      <Card
        size={"small"}
        title={"모페드 매칭"}
      >
        <Descriptions
          bordered
          labelStyle={{width: 200,}}
          size={"small"}
          column={2}
        >
          <DescriptionsItem label={"엑셀 파일 선택"}>
            <MopedTemplateUpload onUpload={setRows}/>
          </DescriptionsItem>
          <DescriptionsItem label={"양식 다운로드"}>
            <MopedTemplateButton/>
          </DescriptionsItem>
        </Descriptions>
      </Card>
      <Card
        size={"small"}
        title={"미리보기"}
        extra={
          <Space>
            <Button type={"primary"} onClick={handleMatch} disabled={!isReady}>
              매칭
            </Button>
            <Button danger onClick={handleClear} disabled={validRows.length === 0}>
              초기화
            </Button>
          </Space>
        }
      >
        <Descriptions
          bordered
          labelStyle={{
            width: 200,
          }}
          size={"small"}
          column={1}
        >
          <DescriptionsItem label={"매칭할 모페드 개수"}>
            {targetCount} / {MAX_COUNT} (대)
          </DescriptionsItem>
        </Descriptions>

        <SheetInput
          columns={columns}
          rows={rows}
          onChange={setRows}
        />
      </Card>
    </>
  )
}

