import {Select} from "antd";
import {ModelName, VehicleType} from "../service/scoot.interface";
import {useEffect, useState} from "react";

export function useModelSelect() {
  const [modelName, setModelName] = useState<ModelName>("S9")

  return {
    modelName, setModelName,
  }
}

export function ModelSelect(args: {
  value: ModelName,
  onChange: (v: ModelName) => void
  vehicleType?: VehicleType
}) {
  const {value, onChange, vehicleType} = args

  const bikeModels: ModelName[] = ["W9"]
  const scootModels: ModelName[] = ["S7", "S9", "S11"]
  const mopedModels: ModelName[] = ["I9"]
  const [options, setOptions] = useState<ModelName[]>([])

  useEffect(() => {
    if (vehicleType === "BIKE") {
      setOptions(bikeModels)
      return
    }

    if (vehicleType === "SCOOTER") {
      setOptions(scootModels)
      return
    }

    if (vehicleType === "MOPED") {
      setOptions(mopedModels)
      return;
    }

    setOptions([...bikeModels, ...scootModels, ...mopedModels])
  }, [vehicleType])

  return (
    <Select style={{width: 200}} value={value} onChange={onChange}>
      {options.map(it => <Select.Option key={it}>{it}</Select.Option>)}
    </Select>
  )
}
