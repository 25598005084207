import {Button, Card, Descriptions, message, Modal} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import {useQrs} from "../scoot/hook/useQrs";
import {BatteryMatchTable} from "../scoot/component/BatteryMatchTable";
import {useProgress} from "../common/api/useProgress";
import {useModalOpen} from "../common/modal/useModalOpen";
import {useLoading} from "../common/api/useLoading";
import {matchScootBattery} from "../scoot/service/scoot.service";

export function MatchBatteryPage() {

  const {qrs: scootQrs, OpenButton: ScootOpenButton, InputModal: ScootInputModal, clear: scootClear} = useQrs()
  const {qrs: batteryQrs, OpenButton: BatteryOpenButton, InputModal: BatteryInputModal, clear: batteryClear} = useQrs()

  const {progress, increaseProgress, clearProgress} = useProgress()
  const {isOpen, open, close} = useModalOpen()
  const {loading, setLoading, setDone} = useLoading()

  async function handleMatch() {
    setLoading()
    for (const i in scootQrs) {
      const scootQr = scootQrs[i]
      const batteryQr = batteryQrs[i]

      await matchScootBattery({batteryQr, scootQr})
        .catch(() => {
          message.error(`${scootQrs} / ${batteryQr} 매칭에 실패했습니다.`)
        })
      increaseProgress()
    }

    setDone()
    close()

    clearProgress()

    message.info("매칭이 완료되었습니다.")

    scootClear()
    batteryClear()
  }

  return (
    <>
      <Modal
        title={`${scootQrs.length} 대 배터리 매칭 진행`}
        open={isOpen}
        confirmLoading={loading}
        closable={!loading}
        onOk={handleMatch}
      >
        {progress === 0 ? ("배터리 매칭을 진행합니다.") : (`배터리 매칭 진행중 ${progress} / ${scootQrs.length}`)}
      </Modal>
      <ScootInputModal title={"킥보드 QR 입력"}/>
      <BatteryInputModal title={"배터리 QR 입력"}/>
      <Card
        title={"배터리 매칭"}
        size={"small"}
      >
        <Descriptions
          bordered
          labelStyle={{
            width: 200,
          }}
          size={"small"}
          column={1}
        >
          <DescriptionsItem label={"킥보드 QR 입력"}><ScootOpenButton/></DescriptionsItem>
          <DescriptionsItem label={"배터리 QR 입력"}><BatteryOpenButton/></DescriptionsItem>
          <DescriptionsItem label={"작업"}>
            <Button type={"primary"} onClick={open}>
              매칭 진행
            </Button>
          </DescriptionsItem>
        </Descriptions>
      </Card>

      <Card
        title={"미리보기"}
        size={"small"}
      >
        <Descriptions
          bordered
          labelStyle={{
            width: 200,
          }}
          size={"small"}
          column={1}
        >
          <DescriptionsItem label={"킥보드 QR"}>{scootQrs.length} 대</DescriptionsItem>
          <DescriptionsItem label={"배터리 QR"}>{batteryQrs.length} 개</DescriptionsItem>
        </Descriptions>

        <BatteryMatchTable scootQrs={scootQrs} batteryQrs={batteryQrs}/>
      </Card>
    </>
  )
}
