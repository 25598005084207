import {API} from "@theswing/sso"
import {AxiosRequestConfig, AxiosResponse} from "axios";

export class HttpException {
  constructor(readonly status: number,  readonly message: string) {
  }
}

class IApi {
  private _api = new API()

  private parseUrl(url: string) {
    return url.replace(/^\//, "")
  }

  async get<T>(api: string, opts?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const response = await this._api.get<T>(this.parseUrl(api), opts?.params)

    if (response.status >= 400) {
      throw new HttpException(response.status, JSON.stringify(response.data))
    }

    return response
  }

  async post<T>(api: string, body = {}): Promise<AxiosResponse<T>> {
    const response = await this._api.post<T>(this.parseUrl(api), body)

    if (response.status >= 400) {
      throw new HttpException(response.status, JSON.stringify(response.data))
    }

    return response
  }

  async delete<T>(api: string, body = {}): Promise<AxiosResponse<T>> {
    const response = await this._api.delete<T>(this.parseUrl(api), body)

    if (response.status >= 400) {
      throw new HttpException(response.status, JSON.stringify(response.data))
    }

    return response
  }

  async put<T>(api: string, body = {}): Promise<AxiosResponse<T>> {
    const response = await this._api.put<T>(this.parseUrl(api), body)

    if (response.status >= 400) {
      throw new HttpException(response.status, JSON.stringify(response.data))
    }

    return response
  }
}

export const Api = new IApi()
