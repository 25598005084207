import {PageRequestDto, PageResponse} from "../../common/api/Pagination";
import {Api} from "../../common/api/Api";
import {SheetAddBody, SheetDto, SheetFilterDto} from "./sheet.interface";

export async function getSheets(args: {
  pageRequest: PageRequestDto,
  filter: SheetFilterDto
}): Promise<PageResponse<SheetDto>> {
  const {filter, pageRequest} = args
  return await Api
    .get<PageResponse<SheetDto>>('/sheets', {
      params: {
        ...pageRequest,
        ...filter,
      },
    })
    .then(resp => resp.data)
}


export async function searchSheets(args: {
  qrs: string[]
}) {
  return await Api
    .get<SheetDto[]>("/sheets/search", {params: {qrs: args.qrs}})
    .then(resp => resp.data)
}

export async function addSheet(body: SheetAddBody) {
  return await Api.post("/sheets", body)
}
