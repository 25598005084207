import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {TableColumn} from "../../common/table/Table";
import {useEffect, useState} from "react";

interface Row {
  battery: string
  scoot: string
}

export function BatteryMatchTable(args: {
  scootQrs: string[],
  batteryQrs: string[]
}) {
  const {scootQrs, batteryQrs} = args

  const [rows, setRows] = useState<Row[]>([])

  useEffect(() => {
    const _rows: Row[] = []
    for (const i in scootQrs) {
      _rows.push({
        scoot: scootQrs[i],
        battery: batteryQrs[i],
      })
    }
    setRows(_rows)
  }, [scootQrs, batteryQrs])

  const columns: ColumnsType<Row> = [
    TableColumn("킥보드", row => row.scoot),
    TableColumn("배터리", row => row.battery),
  ]

  return (
    <Table columns={columns} dataSource={rows}/>
  )
}
