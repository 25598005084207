import {useEffect, useState} from "react";
import {SheetDto, SheetFilterDto} from "../service/sheet.interface";
import {getSheets} from "../service/sheet.service";
import {PageRequestDto} from "../../common/api/Pagination";
import {useLoading} from "../../common/api/useLoading";
import {useFlag} from "../../common/api/useFlag";

export function useSheets(args: {
  filter: SheetFilterDto
  pageRequest: PageRequestDto
}) {
  const {pageRequest, filter} = args

  const [sheets, setSheets] = useState<SheetDto[]>([])
  const [total, setTotal] = useState(0)

  async function fetch() {
    const result = await getSheets({
      filter,
      pageRequest,
    })
    setSheets(result.content)
    setTotal(result.total)
  }

  const {setDone, loading, setLoading} = useLoading()

  const {render, flag} = useFlag()

  useEffect(() => {
    setLoading()
    fetch().then(setDone)
  }, [pageRequest, filter, flag])

  return {
    sheets,
    total,
    loading,
    render,
  }
}
