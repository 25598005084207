import {useEffect, useState} from "react";
import {Button, message, Modal} from "antd";
import TextArea from "antd/es/input/TextArea";

export function useQrs() {
  const [qrs, setQrs] = useState<string[]>([])

  const buttonTitle = qrs.length > 0
    ? `${qrs.length}개 QR 선택됨`
    : "QR 입력"

  const [visible, setVisible] = useState(false)

  function open() {
    setVisible(true)
  }

  function close() {
    setVisible(false)
  }

  function clear() {
    setQrs([])
  }

  function OpenButton() {
    return (
      <>
        <Button onClick={open}>{buttonTitle}</Button>
      </>
    )
  }

  function InputModal(args: {
    title?: string
  }) {
    const {title} = args
    const [input, setInput] = useState("")

    useEffect(() => {
      setInput(qrs.join("\n"))
    }, [qrs])

    function handleOk() {

      const set = new Set<string>()

      const inputs = input.split("\n")
        .map(it => it.trim())
        .filter(it => !!it)

      inputs.forEach(it => set.add(it))

      const newQrs = Array.from(set.values())

      if (inputs.length > newQrs.length) {
        message.warn("중복된 QR이 있습니다.")
      }

      if (newQrs.length > 500) {
        message.warn("QR 입력의 최대 갯수는 500개입니다.")
      }

      setQrs(newQrs.slice(0, 500))

      close()
    }

    function handleCancel() {
      close()
    }

    return (
      <Modal
        title={title ? title : "QR 입력"}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <TextArea
          style={{height: 500}}
          value={input}
          onChange={e => setInput(e.target.value)}
        />
      </Modal>
    )
  }

  return {
    qrs,
    setQrs,
    OpenButton,
    InputModal,
    clear,
  }
}
