import {Select} from "antd";
import {VehicleType} from "../service/scoot.interface";
import {useState} from "react";

export function useVehicleTypeSelect() {
  const [vehicleType, setVehicleType] = useState<VehicleType>("SCOOTER")

  return {
    setVehicleType, vehicleType,
  }
}

export function VehicleTypeSelect(args: {
  value: VehicleType,
  onChange: (v: VehicleType) => void
}) {
  const {value, onChange} = args

  const options: VehicleType[] = ["SCOOTER", "BIKE", "MOPED"]


  return (
    <Select style={{width: 200}} value={value} onChange={onChange}>
      {options.map(it => <Select.Option key={it}>{it}</Select.Option>)}
    </Select>
  )
}
