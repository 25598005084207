import {useEffect, useState} from "react";
import {findScootsByQr} from "../service/scoot.service";
import {ScootDto} from "../service/scoot.interface";
import {useLoading} from "../../common/api/useLoading";
import {useFlag} from "../../common/api/useFlag";

export function useScootsByQr(args: {
  qrs: string[]
}) {
  const {qrs} = args
  const [scoots, setScoots] = useState<ScootDto[]>([])

  const {setDone, setLoading, loading} = useLoading()

  const {flag, render} = useFlag()

  useEffect(() => {
    if (args.qrs.length === 0) {
      setScoots([])
      return
    }

    setLoading()
    fetch().then(setDone)
  }, [args.qrs, flag])

  async function fetch() {
    findScootsByQr({
      qrs,
    })
      .then(setScoots)
  }

  const scootQrs = scoots.map(it => it.qr)
  const invalidQrs = qrs.filter(it => !scootQrs.includes(it))

  return {
    scoots,
    loading,
    invalidQrs,
    render,
  }
}
