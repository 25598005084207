import {useEffect, useState} from "react";
import {getOnlineSessions} from "../service/scoot.service";

export function useOnlineSessions() {
  const [sessions, setSessions] = useState<string[]>([])

  useEffect(() => {
    getOnlineSessions().then(setSessions)
  }, [])

  return {
    sessions,
  }
}
