import {useEffect, useState} from "react";
import {getSpareIots} from "../service/scoot.service";
import {ScootDto} from "../service/scoot.interface";
import {useFlag} from "../../common/api/useFlag";

export function useSpareList() {
  const [spareIots, setSpareIots] = useState<ScootDto[]>([])
  const {flag, render} = useFlag()

  useEffect(() => {
    getSpareIots().then(setSpareIots)
  }, [flag])

  return {
    spareIots,
    render,
  }
}
