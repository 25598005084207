import {useEffect, useState} from "react";
import {SheetDto} from "../service/sheet.interface";
import {searchSheets} from "../service/sheet.service";
import {useLoading} from "../../common/api/useLoading";

export function useSheetsByQr(qrs: string[]) {
  const [sheets, setSheets] = useState<SheetDto[]>([])

  async function fetch() {
    await searchSheets({qrs}).then(setSheets)
  }

  const {setDone, loading, setLoading} = useLoading()

  useEffect(() => {
    if (qrs.length === 0) {
      setSheets([])
      return
    }

    setLoading()
    fetch().then(setDone)
  }, [qrs])

  return {
    sheets,
    loading,
  }
}
