import {Select} from "antd";
import {BandDto} from "../service/band.interface";

export function BandSelect(args: {
  bands: BandDto[],
  selected: BandDto | null,
  onSelect: (id: string) => void
}) {
  const {bands, onSelect, selected} = args

  const options = bands
    .map(it =>
      <Select.Option key={it.bandId}>{it.bandName}</Select.Option>,
    )

  return (
    <Select
      showSearch
      filterOption={(input, option) => {
        const target = (option as any).children as string
        return target.includes(input)
      }}
      style={{width: 200}}
      value={selected?.bandId ?? null}
      onChange={(value) => onSelect(value)}
    >
      {options}
    </Select>
  )
}
