import {ScootDto} from "../service/scoot.interface";
import {ColumnsType} from "antd/es/table";
import {TableColumn} from "../../common/table/Table";
import {Badge, Table} from "antd";
import React from "react";

export function VehicleIotTable(args: {
  spareIots: ScootDto[]
}) {

  const columns: ColumnsType<ScootDto> = [
    TableColumn("ID", row => row.scootId),
    TableColumn("IMEI", row => {
      const isOnline = args.spareIots.find((d) => d.imei === row.imei)
      return <Badge color={isOnline ? "green" : "lightgrey"} text={row.imei}/>
    }),
    TableColumn("MAC", row => row.mac),
    TableColumn("지역", row => row.bandName),
    TableColumn("상태", row => row.status),
  ]

  return (
    <Table
      size={"small"}
      bordered
      scroll={{x: "max-content"}}
      columns={columns}
      loading={false}
      dataSource={args.spareIots}
      pagination={false}
    />
  )
}
