import {Button, Card, Descriptions, message, Modal} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import {useQrs} from "../scoot/hook/useQrs";
import {BandSelect} from "../band/component/BandSelect";
import {useBands} from "../band/hook/useBands";
import {useBandSelect} from "../band/hook/useBandSelect";
import {useScootsByQr} from "../scoot/hook/useScootsByQr";
import {ScootTable} from "../scoot/component/ScootTable";
import {setScootBand} from "../scoot/service/scoot.service";
import {useEffect, useState} from "react";
import {BandDto} from "../band/service/band.interface";

export function MatchBandPage() {

    const {OpenButton, InputModal, qrs} = useQrs()

    const {bands} = useBands()

    const {band, select} = useBandSelect({bands})

    const {scoots, invalidQrs, render} = useScootsByQr({qrs})

    const [prevBands, setPrevBands] = useState<BandDto[]>([])
    const prevBandName = prevBands.map(it => it.bandName).join(",")

    useEffect(() => {
        const ids = new Set<string>()

        for (const scoot of scoots) {
            ids.add(scoot.bandId)
        }

        setPrevBands(bands.filter(it => ids.has(it.bandId)))
    }, [scoots])

    function handleMatch() {
        if (scoots.length === 0) {
            return message.error("킥보드 QR을 먼저 입력해주세요.")
        }

        if (!band || !band.bandId) {
            return message.error("지역을 선택해주세요.")
        }

        if (prevBands.length > 1) {
            return message.error("같은 지역에 소속된 킥보드만 매칭 가능합니다.")
        }

        Modal.confirm({
            title: `${scoots.length}대 킥보드 지역 매칭`,
            content: `선택된 지역 : ${band.bandName}, 기존 지역 : ${prevBandName}`,
            onOk: async () => {
                for (const scoot of scoots) {
                    await setScootBand({
                        bandId: band.bandId,
                        scootId: scoot.scootId,
                    })
                }
                await render()
            },
        })
    }

    return (
        <>
            <InputModal/>

            <Card
                size={"small"}
                title={"지역(밴드) 매칭"}
            >
                <Descriptions
                    bordered
                    labelStyle={{
                        width: 200,
                    }}
                    size={"small"}
                    column={1}
                >
                    <DescriptionsItem label={"QR"}>
                        <OpenButton/>
                    </DescriptionsItem>
                    <DescriptionsItem label={"지역선택"}>
                        <BandSelect selected={band} onSelect={select} bands={bands}/>
                    </DescriptionsItem>
                    <DescriptionsItem label={"매칭하기"}>
                        <Button type={"primary"} onClick={handleMatch}>매칭</Button>
                    </DescriptionsItem>
                </Descriptions>
            </Card>

            <Card
                size={"small"}
                title={"미리보기"}
            >
                <Descriptions
                    bordered
                    labelStyle={{
                        width: 200,
                    }}
                    size={"small"}
                    column={1}
                >
                    <DescriptionsItem label={"지역 ID"}>{band?.bandId ?? "-"}</DescriptionsItem>
                    <DescriptionsItem label={"지역 이름"}>{band?.bandName ?? "-"}</DescriptionsItem>
                    <DescriptionsItem label={"기존 소속 지역"}>{prevBandName}</DescriptionsItem>
                    <DescriptionsItem label={"입력된 QR"}>{qrs.length}개</DescriptionsItem>
                    <DescriptionsItem label={"잘못된 QR"}>{invalidQrs.join(" ")}</DescriptionsItem>
                    <DescriptionsItem label={"검색된 킥보드"}>{scoots.length}대</DescriptionsItem>
                    <DescriptionsItem
                        label={"직영 미소유"}>{scoots.filter(it => it.ownerId !== 1).length}대</DescriptionsItem>
                </Descriptions>
            </Card>

            <Card
                size={"small"}
                title={"킥보드 목록"}
            >
                <ScootTable data={scoots}/>
            </Card>
        </>
    )
}
