import {ColumnsType, ColumnType} from "antd/es/table";
import React, {ReactNode} from "react";
import {AlignType} from "rc-table/lib/interface";
import {Table, TablePaginationConfig} from "antd";
import {PageRequestDto} from "../api/Pagination";

export interface ITableProps<T extends Object> {
  data: T[],
  loading?: boolean
  pagination?: TablePaginationConfig
}

export function TablePagination(args: {
  total: number,
  page: number,
  size: number,
  onPageChange: (v: PageRequestDto) => void
}): TablePaginationConfig {
  const {total, page, size} = args
  return {
    total,
    pageSize: size,
    current: page + 1,
    onChange: (page, size) => {
      args.onPageChange({
        page: page - 1,
        size,
      })
    },
  }
}

export function TableColumn<T>(
  title: string,
  render: (row: T, index: number) => string | ReactNode,
  options?: {
    sorter?: (a: T, b: T) => number,
    align?: AlignType,
  },
): ColumnType<T> {

  const sorter = !!options ? options.sorter : undefined;
  const align = !!options ? options.align : undefined;

  return {
    key: title,
    title: title,
    render: (value, record, index) => render(record, index),
    sorter: sorter,
    align: align ?? "center",
  };
}

export function TableBase<T extends Object>(args: {
  props: ITableProps<T>,
  columns: ColumnsType<T>,
}) {
  return (
    <Table
      size={"small"}
      bordered
      scroll={{x: "max-content"}}
      columns={args.columns}
      loading={args.props.loading}
      dataSource={args.props.data}
      pagination={args.props.pagination}
    />
  )
}
