import {useState} from "react";

export function useSearchInput() {
  const [search, setSearch] = useState("")

  return {
    search,
    setSearch,
  }
}
