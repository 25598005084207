import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.min.css'
import "./index.css"
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {MatchBandPage} from "./page/MatchBandPage";
import {App} from "./App";
import {SheetListPage} from "./page/SheetListPage";
import {ScootRegisterPage} from "./page/ScootRegisterPage";
import {BatteryAddPage} from './page/BatteryAddPage';
import {ScootListPage} from "./page/ScootListPage";
import {MatchBatteryPage} from "./page/MatchBatteryPage";
import {SpareIotListPage} from "./page/SpareIotListPage";
import {MatchMopedPage} from "./page/MatchMopedPage";

export async function sleep() {
  await new Promise(res => setTimeout(res, 500))
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

export const Paths = {
  HOME: "/",
  MATCH_BAND: "/match-band",
  MATCH_BATTERY: "/match-battery",
  MATCH_MOPED: "/match-moped",
  SHEETS: "/sheets",
  SCOOT_REGISTER: "/scooter-register",
  BATTERY_ADD: "/battery-register",
  SCOOT_LIST: "/scoots",
  SPARE_IOT_LIST: "/spare-iots",
}

root.render(
  <BrowserRouter>
    <Routes>
      <Route element={<App/>}>
        <Route path={"/"} element={"Home"}/>
        <Route path={Paths.MATCH_BAND} element={<MatchBandPage/>}/>
        <Route path={Paths.MATCH_BATTERY} element={<MatchBatteryPage/>}/>
        <Route path={Paths.MATCH_MOPED} element={<MatchMopedPage/>}/>
        <Route path={Paths.SHEETS} element={<SheetListPage/>}/>
        <Route path={Paths.SCOOT_REGISTER} element={<ScootRegisterPage/>}/>
        <Route path={Paths.BATTERY_ADD} element={<BatteryAddPage/>}/>
        <Route path={Paths.SCOOT_LIST} element={<ScootListPage/>}/>
        <Route path={Paths.SPARE_IOT_LIST} element={<SpareIotListPage/>}/>
      </Route>
    </Routes>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
