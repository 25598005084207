import {Card, Descriptions, message, Modal, Switch, Table} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import TextArea from "antd/es/input/TextArea";
import {useVehicleTypeSelect, VehicleTypeSelect} from "../../scoot/component/VehicleTypeSelect";
import {ModelSelect, useModelSelect} from "../../scoot/component/ModelSelect";
import {useEffect, useState} from "react";
import {SheetAddBody} from "../service/sheet.interface";
import {addSheet} from "../service/sheet.service";
import {useLoading} from "../../common/api/useLoading";
import {ColumnsType} from "antd/es/table";
import {TableColumn} from "../../common/table/Table";

export function SheetAddModal(args: {
  isOpen: boolean,
  onClose: () => void
}) {

  const {setVehicleType, vehicleType} = useVehicleTypeSelect()
  const {modelName, setModelName} = useModelSelect()

  const [input, setInput] = useState("")
  const [lines, setLines] = useState<string[]>([])

  const [values, setValues] = useState<SheetAddBody[]>([])

  useEffect(() => {
    if (vehicleType === "SCOOTER") {
      setModelName("S9")
    }

    if (vehicleType === "BIKE") {
      setModelName("W9")
    }

    if (vehicleType === "MOPED") {
      setModelName("I9")
    }
  }, [vehicleType])

  useEffect(() => {
    setLines(input.split("\n").map(it => it.trim()).filter(it => !!it))
  }, [input])

  useEffect(() => {
    const result: SheetAddBody[] = []

    lines.forEach(it => {
      const line = it
        .replaceAll(",", "\t")
        .replaceAll("  ", " ")
        .replaceAll(" ", "\t")

      const [qr, imei, _mac] = line.split("\t")
      const random = ((Math.random() + 1 - 1) * 100000000).toFixed(0).padEnd(8, "0")
      const mac = modelName === "S11" ? `AABB${random}` : _mac

      if (!!qr && !!imei && !!mac && mac.length === 12) {
        result.push({
          mac, qr, imei, vehicleType, modelName,
        })
      }
    })

    setValues(result)
  }, [lines, vehicleType, modelName])

  const {loading, setLoading, setDone} = useLoading()

  async function handleOk() {
    if (!isPreview) {
      message.error("미리보기를 확인해주세요")
      return
    }

    setLoading()
    const fails: string[] = []

    for (const value of values) {
      await addSheet(value)
        .then(increaseProgress)
        .catch(() => {
          fails.push(value.qr)
        })
    }

    setDone()
    setProgress(0)

    if (fails.length > 0) {
      message.error(`${fails.length} 개의 시트 등록 실패`)
      setInput(fails.join("\n"))
      return
    }

    message.success(`${values.length} 개의 시트 등록 완료`)
    setInput("")
    args.onClose()
  }

  const [progress, setProgress] = useState(0)

  function increaseProgress() {
    setProgress(prev => prev + 1)
  }

  const placeholder = modelName === "S11" ? "QR IMEI" : "QR IMEI MAC"

  const [isPreview, setIsPreview] = useState(false)

  function Preview() {
    const columns: ColumnsType<SheetAddBody> = [
      TableColumn("QR", row => row.qr),
      TableColumn("IMEI", row => row.imei),
      TableColumn("MAC", row => row.mac),
      TableColumn("기기 종류", row => row.vehicleType),
      TableColumn("모델", row => row.modelName),
    ]

    return (
      <Card title={"입력한 시트"} size={"small"}>
        <Table size={"small"} dataSource={values} columns={columns} pagination={{pageSize: 5}}/>
      </Card>
    )
  }

  return (
    <Modal
      open={args.isOpen}
      title={"시트 등록하기"}
      onCancel={args.onClose}
      onOk={handleOk}
      okText={"등록하기"}
      cancelText={"취소"}
      confirmLoading={loading}
    >
      <Descriptions
        bordered
        size={"small"}
        column={1}
        labelStyle={{width: 120}}
      >
        <DescriptionsItem label={"기기 종류"}>
          <VehicleTypeSelect value={vehicleType} onChange={setVehicleType}/>
        </DescriptionsItem>
        <DescriptionsItem label={"모델"}>
          <ModelSelect value={modelName} onChange={setModelName} vehicleType={vehicleType}/>
        </DescriptionsItem>
        <DescriptionsItem label={"양식"}>{placeholder}</DescriptionsItem>
        <DescriptionsItem label={"입력 대수"}>{values.length}대</DescriptionsItem>
        <DescriptionsItem label={"미리보기"}><Switch checked={isPreview} onChange={setIsPreview}/></DescriptionsItem>
        {progress > 0 && (
          <DescriptionsItem label={"진행률"}>{progress} / {values.length}</DescriptionsItem>
        )}
      </Descriptions>
      {
        isPreview
          ? (
            <Preview/>
          )
          : (
            <TextArea
              style={{height: 280}}
              placeholder={placeholder}
              value={input}
              onChange={e => setInput(e.target.value)}
            />
          )
      }

    </Modal>
  )
}
