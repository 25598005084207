import {Button} from "antd";
import {UploadExcelButton} from "../../common/button/UploadExcelButton";
import {MatchMopedBody} from "../service/scoot.interface";

export function MopedTemplateButton() {
  return (
    <Button type={"primary"} ghost>
      <a href={process.env.PUBLIC_URL + "/template/registerMoped.xlsx"} download>양식 다운로드</a>
    </Button>
  )
}

export function MopedTemplateUpload(props: {
  onUpload: (rows: MatchMopedBody[]) => void
}) {

  function handleUploadedData(lines: string[]) {
    const rows = lines
      .slice(1, lines.length)
      .map<MatchMopedBody>(line => {
        const [imei, vin, lpn] = line.split(",")
        return {imei, lpn, vin}
      })

    props.onUpload(rows)
  }

  return (
    <UploadExcelButton onUpload={handleUploadedData}/>
  )
}