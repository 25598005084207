import { Button, Card, Descriptions, message, Modal } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";

import { BatteryAddTable } from "../battery/component/BatteryAddTable";
import { useQrs } from "../scoot/hook/useQrs";
import { postBatteries } from '../battery/service/battery.service'

export function BatteryAddPage() {
  const { OpenButton, InputModal, qrs, setQrs } = useQrs();

  const targetCount = qrs.length
  const isEmpty = targetCount === 0

  function handleAdd() {
    Modal.confirm({
      title: `${targetCount}개 배터리 생성`,
      onOk: confirm
    });
  }

  async function confirm() {
    for (const qr of qrs) {

      await postBatteries({ qr }).catch(() => {
        message.error(`${qr} 등록에 실패했습니다.`)
      });
    }
    message.success("배터리 생성 완료!")
    clear();
  }

  function clear() {
    setQrs([]);
  }

  return (
    <>
      <InputModal />
      <Card
        size={"small"}
        title={"배터리 등록"}
      >
        <Descriptions
          bordered
          labelStyle={{ width: 200 }}
          size={"small"}
          column={1}
        >
          <DescriptionsItem label={"QR"}>
            <OpenButton />
          </DescriptionsItem>
          <DescriptionsItem label={"생성"}>
            <Button type={"primary"} onClick={handleAdd} disabled={isEmpty}>{targetCount}대 생성</Button>
          </DescriptionsItem>
        </Descriptions>
      </Card>

      <Card
        title={"미리보기"}
        size={"small"}
      >
        <Descriptions
          bordered
          labelStyle={{ width: 200 }}
          size={"small"}
          column={1}
        >
          <DescriptionsItem label={"배터리 QR"}>{targetCount}개</DescriptionsItem>
        </Descriptions>

        <BatteryAddTable qrs={qrs} />
      </Card>
    </>
  )
}
