import {useState} from "react";
import {BandDto} from "../service/band.interface";

export function useBandSelect(args: {
  bands: BandDto[]
}) {
  const [band, setBand] = useState<null | BandDto>(null)

  function select(id: string) {
    const target = args.bands.find(it => it.bandId === id)
    setBand(target ?? null)
  }

  function cancel() {
    setBand(null)
  }

  return {
    cancel,
    select,
    band,
  }
}
