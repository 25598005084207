import {useState} from "react";

export interface PageRequestDto {
  readonly page: number
  readonly size: number
}

export interface PageResponse<T> {
  readonly total: number;
  readonly content: T[];
}

export function usePageRequest() {
  const [pageRequest, setPageRequest] = useState<PageRequestDto>({
    page: 0,
    size: 15,
  })

  return {
    pageRequest,
    setPageRequest,
  }
}
