import {useEffect, useState} from "react";
import {BandDto} from "../service/band.interface";
import {getBands} from "../service/band.service";
import {useLoading} from "../../common/api/useLoading";

export function useBands() {

  const [bands, setBands] = useState<BandDto[]>([])

  const {loading, setLoading, setDone} = useLoading()

  useEffect(() => {
    setLoading()
    fetch().then(setDone)
  }, [])

  async function fetch() {
    getBands().then(setBands)
  }

  return {
    bands,
    loading,
  }

}
