import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Layout, Menu} from "antd";
import {Content} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {Paths} from "./index";
import {ItemType} from "antd/es/menu/hooks/useItems";
import {useEffect, useState} from "react";
import {SSO} from "@theswing/sso";

export function App() {

  const {pathname} = useLocation()

  const navigate = useNavigate()

  useAuth()

  const items: ItemType[] = [
    {
      key: Paths.HOME,
      label: "Home",
    },
    {
      key: Paths.MATCH_BAND,
      label: "지역 매칭",
    },
    {
      key: Paths.MATCH_BATTERY,
      label: "배터리 매칭",
    },
    {
      key: Paths.MATCH_MOPED,
      label: "모페드 매칭",
    },
    {
      key: Paths.SHEETS,
      label: "시트 관리",
    },
    {
      key: Paths.SCOOT_REGISTER,
      label: "킥보드 등록",
    },
    {
      key: Paths.BATTERY_ADD,
      label: "배터리 등록",
    },
    {
      key: Paths.SCOOT_LIST,
      label: "킥보드 관리",
    },
    {
      key: Paths.SPARE_IOT_LIST,
      label: "스페어 관리",
    },
  ]

  return (
    <Layout style={{minHeight: "100vh"}}>
      <Sider collapsible>
        <Menu
          theme={"dark"}
          selectedKeys={[pathname]}
          onClick={e => navigate(e.key)}
          items={items}
        />
      </Sider>

      <Content style={{padding: "32px 16px"}}>
        <Outlet/>
      </Content>
    </Layout>
  )
}

const domain = "match"

async function verify() {
  const sso = new SSO({domain});
  return await sso.verify();
}

function useAuth() {
  const [authenticated, setAuthenticated] = useState(false)

  useEffect(() => {
    verify().then(setAuthenticated)
  }, [])

  return {
    authenticated,
  }
}
