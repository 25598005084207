import {TableBase, ITableProps, TableColumn} from "../../common/table/Table";
import {ColumnsType} from "antd/es/table";
import {SheetDto} from "../service/sheet.interface";

export function SheetTable(args: ITableProps<SheetDto>) {

  const columns: ColumnsType<SheetDto> = [
    TableColumn("ID", row => row.id),
    TableColumn("QR", row => row.qr),
    TableColumn("IMEI", row => row.imei),
    TableColumn("MAC", row => row.mac),
    TableColumn("기기 종류", row => row.vehicleType),
    TableColumn("모델명", row => row.modelName),
    TableColumn("등록일", row => row.createdAt),
    TableColumn("생성여부", row => row.hasInit ? "O" : "-"),
  ]

  return (
    <TableBase props={args} columns={columns}/>
  )
}
