import {useState} from "react";

export function useFlag() {
  const [flag, setFlag] = useState(Math.random())

  function render() {
    setFlag(Math.random())
  }

  return {render, flag}
}
