import {Button, Card, Descriptions} from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import {VehicleIotTable} from "../scoot/component/VehicleIotTable";
import {SpareIotAddModal} from "../scoot/component/SpareIotAddModal";
import {useModalOpen} from "../common/modal/useModalOpen";
import {useSpareList} from "../scoot/hook/useSpareList";
import {useEffect} from "react";

export function SpareIotListPage() {
  const {open, isOpen, close} = useModalOpen()
  const {spareIots, render} = useSpareList();

  useEffect(() => {
    if (!isOpen) {
      render()
    }
  }, [isOpen])

  return (
    <>
      <SpareIotAddModal isOpen={isOpen} onClose={close}/>
      <Card
        size={"small"}
        title={"스페어 IOT 관리"}
      >
        <Descriptions
          bordered
          size={"small"}
          column={1}
          labelStyle={{width: 200}}
        >
          <DescriptionsItem label={"등록"}>
            <Button type={"primary"} onClick={open}>등록하기</Button>
          </DescriptionsItem>
        </Descriptions>
      </Card>

      <Card
        size={"small"}
        title={"스페어 IOT 목록"}
      >
        <VehicleIotTable spareIots={spareIots} />
      </Card>
    </>
  )
}
